import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [10,[2]],
		"/(app)/admin": [11,[2,3]],
		"/(app)/admin/settings": [12,[2,3]],
		"/auth": [36],
		"/auth/reset-password": [37],
		"/(app)/c/[id]": [13,[2]],
		"/error": [38],
		"/join": [39],
		"/(app)/learn": [14,[2,4]],
		"/(app)/manage-team": [15,[2,5]],
		"/(app)/manage-team/invitations": [16,[2,5]],
		"/(app)/manage-team/subscription": [17,[2,5]],
		"/s/[id]": [40],
		"/(app)/workflows": [18,[2,6]],
		"/(app)/workflows/create": [19,[2,6,7]],
		"/(app)/workflows/edit": [20,[2,6,8]],
		"/(app)/workspace": [21,[2,9]],
		"/(app)/workspace/discoveries": [22,[2,9]],
		"/(app)/workspace/discoveries/create": [24,[2,9]],
		"/(app)/workspace/discoveries/[id]": [23,[2,9]],
		"/(app)/workspace/documents": [25,[2,9]],
		"/(app)/workspace/models": [26,[2,9]],
		"/(app)/workspace/models/create": [27,[2,9]],
		"/(app)/workspace/models/edit": [28,[2,9]],
		"/(app)/workspace/playground": [29,[2,9]],
		"/(app)/workspace/prompts": [30,[2,9]],
		"/(app)/workspace/prompts/create": [31,[2,9]],
		"/(app)/workspace/prompts/edit": [32,[2,9]],
		"/(app)/workspace/tools": [33,[2,9]],
		"/(app)/workspace/tools/create": [34,[2,9]],
		"/(app)/workspace/tools/edit": [35,[2,9]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';